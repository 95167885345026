const state = {
  hitProducts: [],
  favorites: [],
  basket: [],
}

const getters = {}

const mutations = {
  SET_HIT_PRODUCTS(state, payload) {
    state.hitProducts = payload
  },

  SET_FAVORITE_PRODUCTS(state, payload) {
    state.favorites = payload
  },

  ADD_PRODUCT_TO_FAVORITES(state, payload) {
    state.favorites.push(payload)
  },

  REMOVE_PRODUCT_FROM_PRODUCTS(state, id) {
    state.favorites = state.favorites.filter((el) => el.id !== id)
  },

  SET_BASKET_PRODUCTS(state, payload) {
    state.basket = payload
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
