import Vue from 'vue'
import Vuex from 'vuex'
import products from '@/store/modules/products'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    user: null,
    token: null,
    categories: [],
    cities: [],
    selectedCity: null,
    topSlides: [],
    secondZoneSlides: [],
    addresses: [],
    novelties: [],
    brands: [],
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload
    },

    SET_USER(state, payload) {
      state.user = payload
      const token = payload?.access_token
      if (token) {
        state.token = token
        localStorage.setItem('uni_token', token)
      }
      localStorage.setItem('uni_user', JSON.stringify(payload))
    },

    LOGOUT(state) {
      state.user = null
      state.token = null
      localStorage.removeItem('uni_token')
      localStorage.removeItem('uni_user')
    },

    SET_CITIES(state, payload) {
      state.cities = payload
    },

    SET_SELECTED_CITY(state, payload) {
      localStorage.setItem('uni_selected_city', JSON.stringify(payload))
      state.selectedCity = payload
    },

    SET_CATEGORIES(state, payload) {
      state.categories = payload
    },

    SET_TOP_SLIDES(state, payload) {
      state.topSlides = payload
    },

    SET_SECOND_ZONE_SLIDES(state, payload) {
      state.secondZoneSlides = payload
    },

    SET_NOVELTIES(state, payload) {
      state.novelties = payload
    },

    SET_ADDRESSES(state, payload) {
      state.addresses = payload
    },

    ADD_ADDRESS(state, payload) {
      state.addresses.push(payload)
    },

    UPDATE_ADDRESS(state, payload) {
      const idx = state.addresses.findIndex((el) => el.id === payload.id)
      state.addresses.splice(idx, 1, payload)
    },

    DELETE_ADDRESS(state, id) {
      const idx = state.addresses.findIndex((el) => el.id === id)
      state.addresses.splice(idx, 1)
    },

    SET_BRANDS(state, payload) {
      state.brands = payload
    },
  },

  actions: {},
  modules: { products },
})
