import axiosInstance from '../axios'
import authApi from './auth.api'
import productsApi from './product.api'
import basketApi from './basket.api'
import orderApi from './order.api'

const Api = {
  getCategories: async () => axiosInstance.get('guest/category-list'),
  getHitProducts: async () => axiosInstance.get('guest/top-favorite-list'),
  getTopSlides: async (query) => axiosInstance.get(`guest/banner-list?${query}`),
  getNovelties: async () => axiosInstance.get('guest/new-product-list'),
  fetchCities: async () => axiosInstance.get('guest/city-list'),
  fetchAddressList: async () => axiosInstance.get('user/address-list'),
  postAddress: async (data) => axiosInstance.post('user/address-add', data),
  deleteAddress: async (data) => axiosInstance.post('user/address-delete', data),
  updateAddress: async (data) => axiosInstance.post('/user/address-update', data),
  fetchFilters: async () => axiosInstance.get('guest/filter-list'),
  updateProfile: async (data) => axiosInstance.post('user/user-update', data),
  fetchFooter: async () => axiosInstance.get('guest/footer-list'),
  fetchFaq: async () => axiosInstance.get('guest/faq-list'),
  getDiscounts: async () => axiosInstance.get('user/get-discount'),
  getSocialNetworkLinks: async () => axiosInstance.get('guest/social-network-list'),
  getBrandList: async () => axiosInstance.get('guest/brand-list'),
  ...authApi,
  ...productsApi,
  ...basketApi,
  ...orderApi,
}

export default Api
