<template>
  <b-overlay :show="isLoading" spinner-variant="success" spinner-type="grow" opacity="0.5">
    <div class="main-layout">
      <div class="page-wrapper">
        <the-header />
        <div class="page-content-wrapper">
          <b-container fluid>
            <router-view />
          </b-container>
        </div>
      </div>
      <the-footer />
      <!--      <mobile-footer />-->
    </div>
  </b-overlay>
</template>

<script>
import TheHeader from '@/components/header/TheHeader'
// import MobileFooter from '@/components/MobileFooter'

export default {
  name: 'MainLayout',
  components: {
    'the-header': TheHeader,
    // MobileFooter,
    'the-footer': () => import('@/components/TheFooter'),
  },
}
</script>
