import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { apiUrl } from '@/config/app.config'

const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.state.token || localStorage.getItem('uni_token')
    if (token) config.headers.Authorization = 'Bearer ' + token
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  (value) => value,
  (error) => {
    const errorResponse = error.response
    if (errorResponse && errorResponse.status === 401) {
      store.commit('LOGOUT')
      router.replace({ name: 'auth.login', query: { redirect: router.currentRoute.name } })
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
