<template>
  <div class="catalog-wrapper">
    <b-skeleton-wrapper :loading="!isCatalogFetched">
      <template #loading>
        <b-skeleton type="button"></b-skeleton>
      </template>
      <button class="button catalog-button" @click="toggleCatalog">
        <svg
          v-show="!isCatalogOpen"
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
        <svg
          v-show="isCatalogOpen"
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </button>
    </b-skeleton-wrapper>

    <transition name="fade">
      <div v-show="isCatalogOpen" class="catalog">
        <ul class="catalog__parent">
          <li
            v-for="item in categories"
            :key="item.id"
            class="catalog__parent-item"
            :class="{ 'catalog__parent-item_active': item.id === activeCategory.id }"
            @mouseover="setActiveCategory(item.id)"
          >
            <router-link class="catalog__link" :to="{ name: 'layout.category', params: { slug: item.slug, page: 1 } }">
              {{ item.name }}
            </router-link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="gray"
              class="bi bi-arrow-right-short"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </li>
        </ul>
        <div class="catalog__child">
          <div class="catalog__child-title mb-4">{{ activeCategory.name }}</div>
          <ul v-if="activeCategory.childs && activeCategory.childs.length" class="catalog__child-list">
            <li v-for="item in activeCategory.childs" :key="item.id" class="catalog__child-item mr-4 mb-3">
              <router-link
                class="catalog__link"
                :to="{ name: 'layout.category', params: { slug: item.slug, page: 1 } }"
              >
                {{ item.name }}
              </router-link>
            </li>
          </ul>
          <p v-else class="empty">Отсутствуют подкатегории</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Api from '@/services/api'

export default {
  name: 'HeaderCatalog',
  data: () => ({
    isCatalogFetched: false,
    isCatalogOpen: false,
    activeCategory: {
      name: '',
      id: 0,
      childs: [],
    },
  }),

  computed: {
    categories() {
      return this.$store.state.categories
    },
  },

  async mounted() {
    if (this.categories.length) {
      this.isCatalogFetched = true
      this.$root.$emit('categories-fetched', true)
      return
    }
    try {
      const { categories } = await this.wrapAndHandleRequest(Api.getCategories, false)
      if (!categories || !Array.isArray(categories)) return
      this.$store.commit('SET_CATEGORIES', categories)
      if (this.categories.length) this.setActiveCategory(categories[0].id)
      await this.sleep(1)
    } finally {
      this.isCatalogFetched = true
      this.$root.$emit('categories-fetched', true)
      this.$root.$on('show-catalog', () => (this.isCatalogOpen = true))
    }
  },

  methods: {
    toggleCatalog() {
      this.isCatalogOpen = !this.isCatalogOpen
    },

    setActiveCategory(id) {
      this.activeCategory = this.categories.find((el) => el.id === id)
    },
  },

  watch: {
    async $route() {
      this.$nextTick(() => (this.isCatalogOpen = false))
    },
  },
}
</script>
