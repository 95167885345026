<template>
  <div class="header-wrapper">
    <header class="header">
      <b-container fluid>
        <nav class="navigation">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center justify-content-center">
              <header-catalog />
              <div class="d-flex align-items-center justify-content-center mx-4">
                <div class="logo navigation__logo">
                  <router-link to="/">
                    <img src="@/assets/images/flexlogo.png" alt="Logo" />
                  </router-link>
                </div>
              </div>
            </div>
            <div class="header-search__wrapper">
              <svg
                v-show="!isSearching"
                width="16"
                height="16"
                viewBox="0 0 18 18"
                fill="currentColor"
                class="header-search__left-icon"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z"
                />
              </svg>
              <b-spinner v-show="isSearching" class="header-search__spinner" small label="Spinning"></b-spinner>
              <b-input
                v-model="searchText"
                @focus="showSearchResults = true"
                debounce="400"
                class="header-search"
                :class="{ 'header-search__focused': showSearchResults }"
                placeholder="Поиск товаров"
                autocomplete="off"
              ></b-input>
              <div v-show="showSearchResults" class="header-search__results">
                <ul class="d-inline-flex flex-column justify-content-center p-0 m-0">
                  <li
                    v-show="!searchResult.length"
                    class="header-search__not-found list-item mb-2 mr-2 align-self-center"
                  >
                    {{ isUserSearched ? 'По вашему запросу ничего не найдено' : 'Введите название товара для поиска' }}
                  </li>
                  <li
                    v-for="result in searchResult"
                    :key="result.id"
                    @click.stop="goToProduct(result.slug)"
                    class="list-item mb-2 mr-2"
                    style="cursor: pointer"
                  >
                    {{ result.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="header__icons align-items-center justify-content-end ml-auto">
              <div
                v-if="isLogged"
                @click="$router.push({ name: 'layout.orders' })"
                class="
                  navigation-item
                  text-white
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                  ml-2
                  mr-4
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="20"
                  fill="currentColor"
                  class="bi bi-clipboard2-check-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z"
                  />
                  <path
                    d="M4.085 1H3.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1h-.585c.055.156.085.325.085.5V2a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 2v-.5c0-.175.03-.344.085-.5Zm6.769 6.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708.708Z"
                  />
                </svg>
                <span class="navigation-item__text mt-1"> Заказы </span>
              </div>
              <div
                v-b-modal.city-modal
                class="navigation-item text-white d-flex flex-column justify-content-center align-items-center mr-4"
              >
                <svg width="22" height="20" viewBox="0 0 22 28" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 0C8.08367 0.00344047 5.28778 1.16347 3.22562 3.22563C1.16347 5.28778 0.00343692 8.08367 -3.5559e-06 11C-0.00349645 13.3832 0.774977 15.7018 2.216 17.6C2.216 17.6 2.516 17.995 2.565 18.052L11 28L19.439 18.047C19.483 17.994 19.784 17.6 19.784 17.6L19.785 17.597C21.2253 15.6996 22.0034 13.3821 22 11C21.9966 8.08367 20.8365 5.28778 18.7744 3.22563C16.7122 1.16347 13.9163 0.00344047 11 0ZM11 15C10.2089 15 9.43551 14.7654 8.77772 14.3259C8.11992 13.8864 7.60723 13.2616 7.30448 12.5307C7.00173 11.7998 6.92251 10.9956 7.07686 10.2196C7.2312 9.44372 7.61216 8.73098 8.17157 8.17157C8.73098 7.61216 9.44371 7.2312 10.2196 7.07686C10.9956 6.92252 11.7998 7.00173 12.5307 7.30448C13.2616 7.60723 13.8863 8.11992 14.3259 8.77772C14.7654 9.43552 15 10.2089 15 11C14.9987 12.0605 14.5768 13.0771 13.827 13.827C13.0771 14.5768 12.0605 14.9987 11 15Z"
                  />
                </svg>
                <span class="navigation-item__text mt-1"> {{ selectedCity }} </span>
              </div>
              <div
                v-if="isLogged"
                @click="goToFavorites()"
                class="navigation-item text-white d-flex flex-column justify-content-center align-items-center mr-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="20"
                  fill="currentColor"
                  class="bi bi-heart-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                  />
                </svg>
                <span class="navigation-item__text mt-1"> Избранные </span>
              </div>
              <div
                v-if="isLogged"
                @click="goToBasket()"
                class="
                  navigation-item
                  position-relative
                  text-white
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                  mr-4
                "
              >
                <svg width="22" height="20" viewBox="0 0 33 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M32.5 10.2224V11.1113C32.5 11.8477 31.9031 12.4446 31.1667 12.4446H30.7222L29.2715 22.5995C29.0838 23.9132 27.9587 24.889 26.6317 24.889H6.36833C5.04128 24.889 3.91617 23.9132 3.72844 22.5995L2.27778 12.4446H1.83333C1.09694 12.4446 0.5 11.8477 0.5 11.1113V10.2224C0.5 9.48599 1.09694 8.88905 1.83333 8.88905H5.5745L11.5067 0.732324C12.0842 -0.0616759 13.196 -0.237287 13.9901 0.340213C14.7842 0.917713 14.9597 2.02955 14.3822 2.8236L9.97094 8.88905H23.0291L18.6178 2.82355C18.0403 2.02955 18.2159 0.917657 19.0099 0.340157C19.8039 -0.237343 20.9158 -0.061787 21.4933 0.732269L27.4255 8.88905H31.1667C31.9031 8.88905 32.5 9.48599 32.5 10.2224ZM17.8333 20.0002V13.7779C17.8333 13.0415 17.2364 12.4446 16.5 12.4446C15.7636 12.4446 15.1667 13.0415 15.1667 13.7779V20.0002C15.1667 20.7365 15.7636 21.3335 16.5 21.3335C17.2364 21.3335 17.8333 20.7365 17.8333 20.0002ZM24.0556 20.0002V13.7779C24.0556 13.0415 23.4586 12.4446 22.7222 12.4446C21.9858 12.4446 21.3889 13.0415 21.3889 13.7779V20.0002C21.3889 20.7365 21.9858 21.3335 22.7222 21.3335C23.4586 21.3335 24.0556 20.7365 24.0556 20.0002ZM11.6111 20.0002V13.7779C11.6111 13.0415 11.0142 12.4446 10.2778 12.4446C9.54139 12.4446 8.94444 13.0415 8.94444 13.7779V20.0002C8.94444 20.7365 9.54139 21.3335 10.2778 21.3335C11.0142 21.3335 11.6111 20.7365 11.6111 20.0002Z"
                  />
                </svg>
                <span class="navigation-item__text mt-1"> Корзина </span>
                <span v-show="productsInBasket" class="basket-number">
                  {{ productsInBasket ? productsInBasket : '' }}
                </span>
              </div>
              <div
                @click="goToLoginOrProfile"
                class="navigation-item text-white d-flex flex-column justify-content-center align-items-center"
              >
                <svg width="20" height="22" viewBox="0 0 33 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.5 8C15.5111 8 14.5444 8.29325 13.7222 8.84265C12.8999 9.39206 12.259 10.173 11.8806 11.0866C11.5022 12.0002 11.4031 13.0055 11.5961 13.9755C11.789 14.9454 12.2652 15.8363 12.9645 16.5355C13.6637 17.2348 14.5546 17.711 15.5246 17.9039C16.4945 18.0969 17.4998 17.9978 18.4134 17.6194C19.3271 17.241 20.1079 16.6001 20.6574 15.7779C21.2068 14.9556 21.5 13.9889 21.5 13C21.5 11.6739 20.9732 10.4021 20.0355 9.46447C19.0979 8.52678 17.8261 8 16.5 8Z"
                  />
                  <path
                    d="M16.5 2C13.7311 2 11.0243 2.82109 8.72202 4.35943C6.41973 5.89777 4.62532 8.08427 3.56569 10.6424C2.50607 13.2006 2.22882 16.0155 2.76901 18.7313C3.30921 21.447 4.64258 23.9416 6.60051 25.8995C8.55845 27.8574 11.053 29.1908 13.7687 29.731C16.4845 30.2712 19.2994 29.9939 21.8576 28.9343C24.4157 27.8747 26.6022 26.0803 28.1406 23.778C29.6789 21.4757 30.5 18.7689 30.5 16C30.4958 12.2883 29.0194 8.72977 26.3948 6.10518C23.7702 3.48059 20.2117 2.00423 16.5 2ZM24.492 24.926C24.4721 23.6143 23.9377 22.363 23.0039 21.4416C22.0702 20.5202 20.8118 20.0025 19.5 20H13.5C12.1882 20.0025 10.9298 20.5202 9.9961 21.4416C9.06235 22.363 8.52794 23.6143 8.50801 24.926C6.69457 23.3067 5.41574 21.1749 4.84084 18.8127C4.26594 16.4505 4.42211 13.9693 5.28864 11.6979C6.15518 9.42637 7.69123 7.47167 9.69338 6.09257C11.6955 4.71347 14.0693 3.97503 16.5005 3.97503C18.9317 3.97503 21.3055 4.71347 23.3076 6.09257C25.3098 7.47167 26.8458 9.42637 27.7124 11.6979C28.5789 13.9693 28.7351 16.4505 28.1602 18.8127C27.5853 21.1749 26.3064 23.3067 24.493 24.926H24.492Z"
                  />
                </svg>

                <span class="navigation-item__text mt-1"> {{ isLogged ? 'Профиль' : 'Войти' }} </span>
              </div>
            </div>
          </div>
        </nav>
        <nav class="navigation-sm">
          <div class="d-flex">
            <div class="d-flex align-items-center justify-content-center">
              <div class="logo navigation__logo">
                <router-link to="/">
                  <img src="@/assets/images/flexlogo.png" alt="Logo" />
                </router-link>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center ml-auto text-white">
              <div v-b-modal.city-modal>
                <svg width="22" height="20" viewBox="0 0 22 28" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 0C8.08367 0.00344047 5.28778 1.16347 3.22562 3.22563C1.16347 5.28778 0.00343692 8.08367 -3.5559e-06 11C-0.00349645 13.3832 0.774977 15.7018 2.216 17.6C2.216 17.6 2.516 17.995 2.565 18.052L11 28L19.439 18.047C19.483 17.994 19.784 17.6 19.784 17.6L19.785 17.597C21.2253 15.6996 22.0034 13.3821 22 11C21.9966 8.08367 20.8365 5.28778 18.7744 3.22563C16.7122 1.16347 13.9163 0.00344047 11 0ZM11 15C10.2089 15 9.43551 14.7654 8.77772 14.3259C8.11992 13.8864 7.60723 13.2616 7.30448 12.5307C7.00173 11.7998 6.92251 10.9956 7.07686 10.2196C7.2312 9.44372 7.61216 8.73098 8.17157 8.17157C8.73098 7.61216 9.44371 7.2312 10.2196 7.07686C10.9956 6.92252 11.7998 7.00173 12.5307 7.30448C13.2616 7.60723 13.8863 8.11992 14.3259 8.77772C14.7654 9.43552 15 10.2089 15 11C14.9987 12.0605 14.5768 13.0771 13.827 13.827C13.0771 14.5768 12.0605 14.9987 11 15Z"
                  />
                </svg>
              </div>
              <div v-if="isLogged" @click="$router.push({ name: 'layout.favorites' })">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="20"
                  fill="currentColor"
                  class="bi bi-heart-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </nav>
      </b-container>
      <b-modal id="city-modal" title="Укажите из какого города вы" hide-footer>
        <ul class="d-flex flex-column justify-content-center align-items-center my-1 p-0">
          <li
            v-for="city in cities"
            :key="city.id"
            @click="setSelectedCity(city)"
            class="list-item mb-2 mr-2"
            style="cursor: pointer"
          >
            {{ city.name }}
          </li>
        </ul>
      </b-modal>
      <b-modal
        id="search-modal"
        title="Глобальный поиск товаров"
        scrollable
        hide-footer
        body-class="global-search__modal"
      >
        <div class="global-search">
          <b-input-group>
            <b-form-input
              v-model="searchText"
              debounce="400"
              placeholder="Поиск товаров"
              autocomplete="off"
            ></b-form-input>

            <b-input-group-append>
              <b-input-group-text
                class="d-flex align-items-center justify-content-center"
                style="width: 54px; background: white"
              >
                <svg
                  @click="clearSearchResults"
                  v-show="!isSearching && searchText.length"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="mx-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
                <b-spinner v-show="isSearching && searchText.length" small label="Spinning"></b-spinner>
              </b-input-group-text>
            </b-input-group-append>
          </b-input-group>
        </div>

        <ul class="d-inline-flex flex-column justify-content-center mt-4">
          <li v-show="!searchResult.length" class="list-item mb-2 mr-2 align-self-center">
            {{ isUserSearched ? 'По вашему запросу ничего не найдено' : 'Введите название товара для поиска' }}
          </li>
          <li
            v-for="result in searchResult"
            :key="result.id"
            @click="goToProduct(result.slug)"
            class="list-item mb-2 mr-2"
            style="cursor: pointer"
          >
            {{ result.name }}
          </li>
        </ul>
      </b-modal>
    </header>
    <b-container fluid class="mt-4">
      <TopCategories />
    </b-container>
    <div class="mobile-footer">
      <div class="footer__icons align-items-center">
        <!--        <div-->
        <!--          @click="showSearchModal"-->
        <!--          class="navigation-item d-flex flex-column justify-content-center align-items-center mr-4"-->
        <!--        >-->
        <!--          <svg width="22" height="20" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">-->
        <!--            <path-->
        <!--              d="M6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.11 12.41 9.59 11.44 10.73L11.71 11H12.5L17.5 16L16 17.5L11 12.5V11.71L10.73 11.44C9.59 12.41 8.11 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 2C4 2 2 4 2 6.5C2 9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2Z"-->
        <!--            />-->
        <!--          </svg>-->
        <!--          <span class="navigation-item__text mt-1"> Поиск </span>-->
        <!--        </div>-->
        <div
          @click="$router.push('/')"
          class="navigation-item d-flex flex-column justify-content-center align-items-center mr-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-house-fill"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
            />
            <path
              fill-rule="evenodd"
              d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
            />
          </svg>
          <span class="navigation-item__text mt-1"> Домой </span>
        </div>
        <mobile-catalog />
        <div
          v-if="isLogged"
          @click="$router.push({ name: 'layout.orders' })"
          class="navigation-item d-flex flex-column justify-content-center align-items-center mr-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-clipboard2-check-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z"
            />
            <path
              d="M4.085 1H3.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1h-.585c.055.156.085.325.085.5V2a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 2v-.5c0-.175.03-.344.085-.5Zm6.769 6.854-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708.708Z"
            />
          </svg>
          <span class="navigation-item__text mt-1"> Заказы </span>
        </div>
        <div
          v-if="isLogged"
          @click="goToBasket"
          class="navigation-item position-relative d-flex flex-column justify-content-center align-items-center mr-1"
        >
          <svg width="16" height="16" viewBox="0 0 33 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M32.5 10.2224V11.1113C32.5 11.8477 31.9031 12.4446 31.1667 12.4446H30.7222L29.2715 22.5995C29.0838 23.9132 27.9587 24.889 26.6317 24.889H6.36833C5.04128 24.889 3.91617 23.9132 3.72844 22.5995L2.27778 12.4446H1.83333C1.09694 12.4446 0.5 11.8477 0.5 11.1113V10.2224C0.5 9.48599 1.09694 8.88905 1.83333 8.88905H5.5745L11.5067 0.732324C12.0842 -0.0616759 13.196 -0.237287 13.9901 0.340213C14.7842 0.917713 14.9597 2.02955 14.3822 2.8236L9.97094 8.88905H23.0291L18.6178 2.82355C18.0403 2.02955 18.2159 0.917657 19.0099 0.340157C19.8039 -0.237343 20.9158 -0.061787 21.4933 0.732269L27.4255 8.88905H31.1667C31.9031 8.88905 32.5 9.48599 32.5 10.2224ZM17.8333 20.0002V13.7779C17.8333 13.0415 17.2364 12.4446 16.5 12.4446C15.7636 12.4446 15.1667 13.0415 15.1667 13.7779V20.0002C15.1667 20.7365 15.7636 21.3335 16.5 21.3335C17.2364 21.3335 17.8333 20.7365 17.8333 20.0002ZM24.0556 20.0002V13.7779C24.0556 13.0415 23.4586 12.4446 22.7222 12.4446C21.9858 12.4446 21.3889 13.0415 21.3889 13.7779V20.0002C21.3889 20.7365 21.9858 21.3335 22.7222 21.3335C23.4586 21.3335 24.0556 20.7365 24.0556 20.0002ZM11.6111 20.0002V13.7779C11.6111 13.0415 11.0142 12.4446 10.2778 12.4446C9.54139 12.4446 8.94444 13.0415 8.94444 13.7779V20.0002C8.94444 20.7365 9.54139 21.3335 10.2778 21.3335C11.0142 21.3335 11.6111 20.7365 11.6111 20.0002Z"
            />
          </svg>
          <span class="navigation-item__text mt-1"> Корзина </span>
          <span v-show="productsInBasket" class="basket-number basket-number__mobile">
            {{ productsInBasket ? productsInBasket : '' }}
          </span>
        </div>
        <div
          @click="goToLoginOrProfile"
          class="navigation-item d-flex flex-column justify-content-center align-items-center"
        >
          <svg width="16" height="16" viewBox="0 0 33 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.5 8C15.5111 8 14.5444 8.29325 13.7222 8.84265C12.8999 9.39206 12.259 10.173 11.8806 11.0866C11.5022 12.0002 11.4031 13.0055 11.5961 13.9755C11.789 14.9454 12.2652 15.8363 12.9645 16.5355C13.6637 17.2348 14.5546 17.711 15.5246 17.9039C16.4945 18.0969 17.4998 17.9978 18.4134 17.6194C19.3271 17.241 20.1079 16.6001 20.6574 15.7779C21.2068 14.9556 21.5 13.9889 21.5 13C21.5 11.6739 20.9732 10.4021 20.0355 9.46447C19.0979 8.52678 17.8261 8 16.5 8Z"
            />
            <path
              d="M16.5 2C13.7311 2 11.0243 2.82109 8.72202 4.35943C6.41973 5.89777 4.62532 8.08427 3.56569 10.6424C2.50607 13.2006 2.22882 16.0155 2.76901 18.7313C3.30921 21.447 4.64258 23.9416 6.60051 25.8995C8.55845 27.8574 11.053 29.1908 13.7687 29.731C16.4845 30.2712 19.2994 29.9939 21.8576 28.9343C24.4157 27.8747 26.6022 26.0803 28.1406 23.778C29.6789 21.4757 30.5 18.7689 30.5 16C30.4958 12.2883 29.0194 8.72977 26.3948 6.10518C23.7702 3.48059 20.2117 2.00423 16.5 2ZM24.492 24.926C24.4721 23.6143 23.9377 22.363 23.0039 21.4416C22.0702 20.5202 20.8118 20.0025 19.5 20H13.5C12.1882 20.0025 10.9298 20.5202 9.9961 21.4416C9.06235 22.363 8.52794 23.6143 8.50801 24.926C6.69457 23.3067 5.41574 21.1749 4.84084 18.8127C4.26594 16.4505 4.42211 13.9693 5.28864 11.6979C6.15518 9.42637 7.69123 7.47167 9.69338 6.09257C11.6955 4.71347 14.0693 3.97503 16.5005 3.97503C18.9317 3.97503 21.3055 4.71347 23.3076 6.09257C25.3098 7.47167 26.8458 9.42637 27.7124 11.6979C28.5789 13.9693 28.7351 16.4505 28.1602 18.8127C27.5853 21.1749 26.3064 23.3067 24.493 24.926H24.492Z"
            />
          </svg>

          <span class="navigation-item__text mt-1"> {{ isLogged ? 'Профиль' : 'Войти' }} </span>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-show="isCatalogOpen" class="catalog mobile-catalog">
        <div class="mobile-catalog__head mb-2">
          <svg
            @click="handleCatalogBackButton()"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-arrow-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
          <div @click="hideCatalogAndShowSearch" class="mobile-catalog__search-placeholder">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              />
            </svg>
            <span class="ml-2"> Поиск в магазине </span>
          </div>
        </div>
        <ul v-show="!showChildCatalog" class="catalog__parent">
          <li
            v-for="item in categories"
            :key="item.id"
            class="catalog__parent-item"
            :class="{ 'catalog__parent-item_active': item.id === activeCategory.id }"
            @click="setActiveCategory(item.id)"
          >
            <span class="catalog__link">
              {{ item.name }}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="gray"
              class="bi bi-arrow-right-short"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
              />
            </svg>
          </li>
        </ul>
        <div v-show="showChildCatalog" class="catalog__child">
          <div class="catalog__child-title mb-4">{{ activeCategory.name }}</div>
          <ul v-if="activeCategory.childs && activeCategory.childs.length" class="catalog__child-list">
            <li class="catalog__child-item mb-3">
              <router-link
                class="catalog__link"
                :to="{ name: 'layout.category', params: { slug: activeCategory.slug, page: 1 } }"
              >
                Все товары
              </router-link>
            </li>
            <li v-for="item in activeCategory.childs" :key="item.id" class="catalog__child-item mr-4 mb-3">
              <router-link
                class="catalog__link"
                :to="{ name: 'layout.category', params: { slug: item.slug, page: 1 } }"
              >
                {{ item.name }}
              </router-link>
            </li>
          </ul>
          <template v-else>
            <ul class="catalog__child-list">
              <li class="catalog__child-item">
                <router-link
                  class="catalog__link"
                  :to="{ name: 'layout.category', params: { slug: activeCategory.slug, page: 1 } }"
                >
                  Все товары
                </router-link>
              </li>
            </ul>
            <p class="empty">Отсутствуют подкатегории</p>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import HeaderCatalog from '@/components/header/HeaderCatalog'
import TopCategories from '@/components/header/TopCategories'
import Api from '@/services/api'
import MobileCatalog from '@/components/header/MobileCatalog'

export default {
  name: 'TheHeader',
  components: {
    MobileCatalog,
    HeaderCatalog,
    TopCategories,
  },

  data: () => ({
    isSearching: false,
    searchText: '',
    searchResult: [],
    isCatalogOpen: false,
    showChildCatalog: false,
    isUserSearched: false,
    showSearchResults: false,
    activeCategory: {
      name: '',
      id: 0,
      childs: [],
    },
  }),

  computed: {
    cities() {
      return this.$store.state.cities
    },

    categories() {
      return this.$store.state.categories
    },

    selectedCity() {
      if (!this.$store.state.selectedCity) return 'Город'
      return this.$store.state.selectedCity.name
    },

    basketItems() {
      return this.$store.state.products.basket
    },

    productsInBasket() {
      if (!this.basketItems.length) return 0
      return this.basketItems.reduce((accumulate, item) => (accumulate += item.quantity), 0)
    },

    isLogged() {
      const token = this.$store.state.token
      if (token) return true
      const lsToken = localStorage.getItem('uni_token')
      return !!lsToken
    },
  },

  async mounted() {
    await this.fetchCities()
    this.$root.$on('show-mobile-catalog', () => (this.isCatalogOpen = true))
    if (!this.$store.state.selectedCity) {
      this.$bvModal.show('city-modal')
    }
  },

  beforeDestroy() {
    document.removeEventListener('click', this.hideSearchResultsBlock)
  },

  watch: {
    async searchText(newVal) {
      if (!newVal || !newVal.length) {
        this.isUserSearched = false
        return (this.searchResult = [])
      }
      try {
        this.isSearching = true
        const { data } = await this.wrapAndHandleRequest(() => Api.filterProducts(`name=${newVal}`), false)
        this.isUserSearched = true
        this.searchResult = data
      } finally {
        this.isSearching = false
      }
    },

    async $route() {
      this.$nextTick(() => (this.isCatalogOpen = false))
    },

    showSearchResults(value) {
      value
        ? document.addEventListener('click', this.hideSearchResultsBlock)
        : document.removeEventListener('click', this.hideSearchResultsBlock)
    },
  },

  methods: {
    hideSearchResultsBlock(e) {
      if (!e.target.classList.contains('header-search')) {
        this.showSearchResults = false
      }
    },

    setSelectedCity(city) {
      this.$store.commit('SET_SELECTED_CITY', city)
      this.$bvModal.hide('city-modal')
    },

    handleCatalogBackButton() {
      if (this.showChildCatalog) {
        return (this.showChildCatalog = false)
      }
      this.isCatalogOpen = false
    },

    hideCatalogAndShowSearch() {
      this.showChildCatalog = false
      this.isCatalogOpen = false
      this.$nextTick(() => {
        this.showSearchModal()
      })
    },

    setActiveCategory(id) {
      this.activeCategory = this.categories.find((el) => el.id === id)
      this.$nextTick(() => (this.showChildCatalog = true))
    },

    clearSearchResults() {
      this.searchText = ''
      this.searchResult = []
    },

    goToFavorites() {
      this.$router.push({ name: 'layout.favorites' })
    },

    async fetchCities() {
      const selectedCity = localStorage.getItem('uni_selected_city')
      if (selectedCity) this.$store.commit('SET_SELECTED_CITY', JSON.parse(selectedCity))
      if (this.cities.length) return
      const { cities } = await this.wrapAndHandleRequest(Api.fetchCities)
      this.$store.commit('SET_CITIES', cities)
    },

    goToLoginOrProfile() {
      if (this.isLogged) return this.$router.push({ name: 'layout.profile' })
      this.$router.push({ name: 'auth.login' })
    },

    goToBasket() {
      this.$router.push({ name: 'layout.basket' })
    },

    goToProduct(slug) {
      this.$router.push({ name: 'layout.product', params: { slug } })
      this.$nextTick(() => {
        this.showSearchResults = false
        this.searchText = ''
        this.hideSearchModal()
      })
    },

    showSearchModal() {
      this.$bvModal.show('search-modal')
    },

    hideSearchModal() {
      this.$bvModal.hide('search-modal')
    },
  },
}
</script>
