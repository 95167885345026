import axios from '../axios'

export default {
  login: (data) => axios.post('auth/sign-in', data),
  registerSms: (data) => axios.post('auth/registration-sms', data),
  fullRegisterSms: (data) => axios.post('auth/registration', data),
  activation: (data) => axios.post('auth/activation', data),
  resetPasswordBySms: (data) => axios.post('auth/reset-password-sms', data),
  resetPasswordRequest: (data) => axios.post('auth/reset-password-request', data),
}
