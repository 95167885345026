import axios from '../axios'

export default {
  updateCart: async (data) => axios.post('user/cart-update', data),
  fetchCartList: async () => axios.get('user/cart-list'),
  fetchDeliveryTypes: async () => axios.get('guest/delivery-type-list'),
  getDeliveryPrice: async (body) => axios.post('user/get-delivery-amount', body),
  getDeliveryDate: async (query) => axios.get(`user/get-date?${query}`),
  getGuestsDeliveryPrice: async (body) => axios.post('guest/get-delivery-amount', body),
  getGuestsDeliveryDate: async (query) => axios.get(`guest/get-date?${query}`),
  getPromoDiscount: async (body) => axios.post('user/get-promo-discount', body),
  getMinOrderSum: async () => axios.get('guest/get-min-order-sum'),
  getPackageCost: async () => axios.get('guest/get-package-cost'),
}
