import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'

const mixin = {
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
  },

  methods: {
    ...mapMutations({
      setLoading: 'SET_LOADING',
    }),

    handleErrors(e, errorTitle = 'Ошибка') {
      let msg = 'Произошла ошибка... Обратитесь в тех.поддержку'
      const errorResponse = e.response
      console.log(errorResponse)
      if (errorResponse) {
        if (errorResponse.status === 401) return
        if (errorResponse?.data?.message) {
          msg = errorResponse.data.message
          return this.makeToast(errorTitle, msg, 'danger', false)
        }

        if (errorResponse.data?.errors && errorResponse.data.errors.length) {
          errorResponse.data.errors.forEach((err) => {
            this.makeToast(errorTitle, err.msg, 'danger', false)
          })
          return
        }
      }

      this.makeToast(errorTitle, msg, 'danger', true)
    },

    makeToast(title = '', body = '', variant = null, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        noAutoHide,
      })
    },

    async sleep(sec) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(), sec * 1000)
      })
    },

    async wrapAndHandleRequest(request, blockUi = true) {
      try {
        if (blockUi) this.setLoading(true)
        const response = await request()
        if (response && response.data) return response.data
      } catch (e) {
        this.handleErrors(e)
        throw e
      } finally {
        if (blockUi) this.setLoading(false)
      }
    },
  },
}

Vue.mixin(mixin)
