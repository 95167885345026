<template>
  <div class="empty-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'EmptyLayout',
}
</script>
