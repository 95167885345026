import axios from '../axios'

export default {
  filterProducts: async (query) => axios.get(`guest/product-filter?${query}`),
  fetchProduct: async (slug) => axios.get(`guest/product-detail?slug=${slug}`),
  fetchSimilarProducts: async (slug) => axios.get(`guest/similar-products?slug=${slug}&limit=8`),
  checkQuantity: async (body) => axios.post('guest/check-quantity', body),
  addRating: async (body) => axios.post('user/rating-add', body),
  saveToFavorite: async (body) => axios.post('user/favorite-save', body),
  deleteFromFavorites: async (body) => axios.post('user/favorite-delete', body),
  fetchFavoriteList: async () => axios.get('user/favorite-list'),
}
